<template>
  <div class="dashboard-table-card-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <template>
        <div class="container">
          <div class="title-box">
            <div class="card-title" style="display: flex; padding-top: 0px">
              绩效管理
              <div class="num-box">{{ pageOptions.total }}人</div>
            </div>
            <le-tabs style="margin-left: 30px" @change="changeTab" />
          </div>

          <div class="search-box"  @keypress="keyPress($event)"  >
            <div style="display: flex;" >
              <a-tree-select
                  style="width:200px; margin-right: 10px;"
                  :tree-data="list"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择经纪人"
                  v-model="pid"
                  @change="handlePidChange"
                >
              </a-tree-select>

                <a-input-search
                    enter-button="搜索"
                    style="width: 300px;"
                    placeholder="请输入搜索内容"
                    v-model="word"
                    @search="onSearch"
                    :allowClear="true"
                  >
              <a-select slot="addonBefore" v-model="type" style="width: 90px">
                  <a-select-option value="uname">
                    昵称
                  </a-select-option>
                  <a-select-option value="uid">
                    UID
                  </a-select-option>
                </a-select>
                </a-input-search>
            </div>
            <le-button :loading="isLoad"   class="btn-search" txt="导出"   @btnClick="isLoad=true;downloadExcel()"  v-if="isExport"   />
          </div>

          <div class="tips-box">
            统计时间：{{dateData.start_at}}~{{dateData.close_at}}(今日数据有一定延迟，以次日结果为准)
          </div>
          <div class="table-box">
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :loading="loading"
              :pagination="pageOptions"

              @change="handleTableChange"
              size="middle"
            >
              <div slot="uname" slot-scope="row">
                <a
                  class="name-box"
                  :href="'https://space.bilibili.com/' + row.uid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="avatar">
                    <img :src="row.face_url" alt="" srcset="" />
                  </div>
                  <div class="name-uid">
                    <div>{{ row.uname }}</div>
                    <div>UID:{{ row.uid }}</div>
                    <div>上级UID:{{ row.pid }}</div>
                  </div>
                </a>
              </div>

              <span slot="action" style="display: flex;justify-content:space-between" slot-scope="row">
                <le-button txt="查看" width="40px" height="30px" fontSize="14px" @btnClick="clickView(row)" />
              </span>
            </a-table>
            <popEdit v-if="visible" :visible="visible"  @handleOk="visible=false"  @handleCancel="visible=false" :row="dateData"  />
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
const columns = [
  {
    title: "昵称/UID",
    scopedSlots: { customRender: "uname" },
    width: 200,
  },
  {
    title: "角色名称",
    dataIndex: "role_name",
  },
  {
    title: "流水",
    dataIndex: "flow",
    sorter:(a,b)=>a.flow-b.flow
  },
  // {
  //   title: "收益",
  //   dataIndex: "income",
  // },
  {
    title: "新增主播",
    dataIndex: "new_anchor",
    sorter:(a,b)=>a.new_anchor-b.new_anchor
  },
  {
    title: "新增主播大航海",
    dataIndex: "new_anchor_guard",
    sorter:(a,b)=>a.new_anchor_guard-b.new_anchor_guard
  },
  {
    title: "职业主播数",
    dataIndex: "super_anchor",
    sorter:(a,b)=>a.super_anchor-b.super_anchor
  },
  {
    title: "操作",
    width: 110,
    key: 'action',
    scopedSlots: { customRender: "action" },
  }
];

import { MixinList } from "@/common/mixin";
import { urlencoded } from "body-parser";
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import popEdit from './popEdit'

export default {
  data() {
    return {
      columns,
      dataUrl: "v2/broker/manage",
      delUrl: "",
      list:null,
      dateData:{
        start_at: '',
        close_at: '',
      },
      type: 'uname',
      pid:'19898131',
      visible: false,
      word:'',
      isExport: false,
      isLoad: false
    };
  },
  components:{
    popEdit
  },
  computed: {},
  mixins: [MixinList],
  created(){
    this.dateData.start_at =moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 00:00:00')
    this.dateData.close_at=moment(new Date()).add(-1, 'days').format('YYYY-MM-DD 23:59:59')

    let btn=window.localStorage.getItem('whiteBtns')
    if(btn){
      let btns=btn.split(',');
      if(btns.includes('broker-kpi-export')){
        this.isExport = true;
      }
    }


    //获取角色列表
    this.getRoles();
  },
  beforeMount() {
    this.queryParam.pid = '19898131';
  },
  mounted() {

  },
  methods: {
    //监听回车键
    keyPress(e){
      if(e.charCode==13){
        this.onSearch(this.word)
      }
    },
    //查看主播新增列表
    clickView(row){
      this.visible = true;
      this.dateData.uid=row.uid
    },

    onClearSearch() {
        this.queryParam.delete('keyword')
        this.queryParam.delete('search_by')
        this.queryParam.page = 1;
        this.getData();
    },

   //搜索
  onSearch(value) {
    if(!value){
      delete this.queryParam.keyword
      delete this.queryParam.search_by
    }else{
    this.queryParam.keyword =value
    this.queryParam.search_by = this.type
    }
    this.queryParam.page = 1;
    console.log(this.queryParam);
    this.getData();
  },

   //获取角色列表
    getRoles(){
      this.$api.get(`v2/admin/sel`).then((res) => {
        console.log(res);
        if(res.code==200){
          this.list=res.data
        }else{
          this.$message.error(res.message)
        }
      });
    },

    handlePidChange(value){
      console.log(value);
      this.queryParam.pid=value;
      this.getData();
    },

    //导出excle表格
    downloadExcel(){
      let params=  JSON.parse(JSON.stringify(this.queryParam));
      params.page=1
      params.perPage=this.pageOptions.total
      this.$api.get(this.dataUrl,{params}).then((res) => {
      if(res.code==200) {
        const datas = res.data.list
        var option = {};
        let dataTable = [];
        if (datas) {
          for (let i in datas) {
            let obj = {
              'ID': datas[i].id,
              '昵称': datas[i].uname,
              'uid': datas[i].uid,
              '上级id': datas[i].pid,
              '角色名称': datas[i].role_name,
              '流水': datas[i].flow,
              '收益': datas[i].income,
              '新增主播': datas[i].new_anchor,
              '新增主播大航海': datas[i].new_anchor_guard,
              '大余5000主播数': datas[i].super_anchor
              }
            dataTable.push(obj);
          }
        }
        option.fileName = '主播列表'
        option.datas = [
          {
            sheetData: dataTable,
            sheetName: 'sheet',
            sheetFilter: ['ID', '昵称', 'uid', '上级id','角色名称','流水','收益','新增主播','新增主播大航海','大余5000主播数'],
            sheetHeader: ['ID', '昵称', 'uid', '上级id','角色名称','流水','收益','新增主播','新增主播大航海','大余5000主播数'],
          },
          {
            sheetData: dataTable,
            sheetName: 'sheet2',
            sheetHeader: ['ID', '昵称', 'uid', '上级id','角色名称','流水','收益','新增主播','新增主播大航海','大余5000主播数'],
          }
        ];
        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
        this.isLoad=false;
      }else{
        this.$message.error(res.message)
        this.isLoad=false;

      }
      })
    },
    changeTab(json) {
      console.log(json);
      this.dateData.start_at =json.start
      this.dateData.close_at=json.end
      this.queryParam.start_at = encodeURI(json.start);
      this.queryParam.close_at = encodeURI(json.end);
      this.queryParam.page = 1;
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
  .container {
    position: relative;
    .title-box {
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
    }

    .search-box{
      display: flex;
      justify-content: space-between;
      margin: 20px 30px;
    }


    .num-box {
      font-size: 14px;
      margin-left: 11px;
      line-height: 32px;
    }

    .tips-box {
      margin-top: 20px;
      margin-left: 30px;
      font-size: 12px;
      color: #aaa;
    }

    .table-box {
      margin: 40px 30px;
      margin-top: 10px;
    }
  }
}

.btn-canle {
  width: 70px;
  height: 30px;
  background: linear-gradient(-10deg, #fd8a8f, #fa6a9d);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-left: 10px;
}

.login {
  cursor: pointer;
  &:hover {
    color: #008df0;
  }
}

.name-box {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .dashboard-table-card-container {
    .container {
      .title {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 20px;
        .num-box {
          font-size: 12px;
          margin-left: 5px;
          line-height: 22px;
        }
      }
      .le-button {
        position: absolute;
        top: 30px;
        right: 20px;
      }

      .table-box {
        margin: 20px;
        overflow-x: scroll;
      }
    }
  }
}
</style>
