<template>
  <div class="card-container">
    <le-card width="300px" height="100px">
      <template>
        <div class="container">
          <div class="title">
            {{ title }}
            <a-tooltip>
              <template slot="title"> {{tips}} </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
          <div class="num-box">
            <div class="num">{{ num }}</div>
            <div class="unit">{{ unit }}</div>
          </div>
        </div>
      </template>
    </le-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      search: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    num: {
      type: [String, Number],
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    tips: {
      type: String,
      default: "",
    },
  },
  computed: {},
  mounted() {},
  methods: {
    searchClick() {
      console.log(this.search);
      if (!this.search) {
        this.$message.error("请填入主播UID");
        return false;
      }

      tool.$emit("searchOut", this.search);
    },
    changeSearch() {
      console.log("123");
      if (!this.search) {
        tool.$emit("clearOut");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-container {
  margin-left: 30px;
  .container {
    position: relative;
    .title {
      padding-top: 20px;
      margin-left: 30px;
      color: #333333;
    }
    .num-box{
      margin-left: 30px;
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      .num{
        font-size: 24px;
        font-weight: bold;
        margin-right: 5px;
      }
      .unit{
        font-size: 14px;
      }
    }

  }
}
</style>
